import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.product',
      search: 'product.search_keyword',
      filter: {
        status: {
          label: 'data.status',
          type: 'selection',
          options: [
            {text: '全部', value: 'null'},
            {text: vm.$t('action.up'), value: 'true'},
            {text: vm.$t('action.downed'), value: 'false'}
          ],
        },
      },
      selectedData: row => row,
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      card: {
        title: row => row.product.name,
        // subtitle: row => row.product.status ? '上架' : '下架',
        avatarPhoto: row => row.product.photos,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'product.name',
            label: 'data.name',
            type: 'text',
          },
          {
            key: 'product.price',
            label: 'product.data.price',
            type: 'text',
            text: (row) => {
              const product = row.product
              return product.price * product.per_shipping_count
            },
          }
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.product[0].name,
        },
      },
      metaAction: {
        create: {
          label: '新增商品',
          linkTarget: '_self',
          type: 'route',
          component: () => import('@/components/list/listMetaAction/roundedBtn.vue'),
          route: () => ({ name: 'product-menu-create-item' }),
          // callback: async() => {
          //   // 取得所有該分類底下的商品（不分頁）
          //   // @ts-ignore
          //   const allPivots = await this.vm.indexApi({pager:0})
          //   const usedList = allPivots.map((pivot:any) => pivot.product_id)
          //
          //   // @ts-ignore
          //   this.vm.$productSelector({
          //     multiple: true,
          //     // @ts-ignore
          //     usedList,
          //     usedListMatchCallback: (usedListRow:any, row:any) => {
          //       return usedListRow == row.id
          //     },
          //     applyCallback: async (data: any) => {
          //       const action = this.vm?.$store.getters[`list/${this.listKey}/actions`]
          //       const providerId = this.vm?.$store.getters[`member/providerId`]
          //       const menu_id = this.vm?.$route.params.target
          //       const product_ids = data.map((product: any) => product.id)
          //       const params = {
          //         product_ids
          //       }
          //       // @ts-ignore
          //       this.vm.$store.dispatch(`loading/active`)
          //       try {
          //         await this.vm?.$api.collection.productMenuPivotApi.create(providerId, menu_id, params)
          //         await action.index()
          //         // @ts-ignore
          //         this.vm.$snotify.success(null, this.vm.$t('action.add.success'))
          //       } catch(err) {
          //         // @ts-ignore
          //         this.vm.$snotify.error(null, this.vm.$t('action.add.fail'))
          //         console.error(err)
          //       } finally {
          //         // @ts-ignore
          //         this.vm.$store.dispatch(`loading/close`)
          //       }
          //
          //     },
          //   })
          // }
        },
      },
      dataAction: {
        // update: {
        //   label: 'action.edit',
        //   route: row => ({ name: 'product-update', params: { target: row.id } }),
        //   linkTarget: '_self',
        //   color: 'primary'
        // },
        // delete: {
        //   removeTarget: row => row.name,
        // },
      },
    }

  }
}

export default new listConfig()
