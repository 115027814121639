<template>
    <div class="mb-4">
        <!-- 額外列表操作 -->
        <div class="d-flex justify-end">
            <listMetaAction
                    :list-key="listKey"
            ></listMetaAction>
        </div>
        <div class="px-2">
            <listSearchBar
                    class="my-4"
                    :listKey="listKey"
            ></listSearchBar>
        </div>

        <div class="d-flex justify-space-between px-4 pb-4">
            <!-- 全選/取消全選 -->
            <div class="d-flex align-center pl-4" >
                <v-checkbox
                        v-if="createSelectAllButton"
                        v-model="hasSelectedAll"
                        @click="toggleSelectAll"
                        class="absolute"
                >
                </v-checkbox>
                <div class="pl-10">全選</div>
            </div>


            <!-- 批次操作 -->
            <batch-delete
                    :key="`batch-delete`"
                    :list-key="listKey"
                    :config="`delete`"
            ></batch-delete>
        </div>
        <v-divider/>

        <!-- 條列模式 -->
        <listRowMode
                class="my-4"
                :listKey="listKey"
        ></listRowMode>
    </div>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './productMenuPivotList'
export default {
  mixins: [listMixin],
  data: () => ({
    bindRoute: false,
    listKey: 'product-menu-product-list',
    meta: {},
  }),
    components: {
        listSearchBar: () => import('@/components/list/listSearchBar.vue'),
        listMetaAction: () => import('@/components/list/listMetaAction.vue'),
        listRowMode: () => import('@/components/list/listRowMode'),
        batchDelete: () => import('@/components/list/listToolbar/batchDelete.vue'),
    },
  computed: {
    target() {
      return this.$route.params.target
    },
    params() {
      return this.$store.getters[`list/${this.listKey}/indexParams`]
    },
    providerId() {
      return this.$store.getters['member/providerId']
    },
      createSelectAllButton() {
          if(!this.hasData) return false
          if(!this.hasSelectFeature) return false
          if(this.isPopupMode) return false // module selector模式不使用全選toggle功能
          return true
      },
      hasSelectFeature() {
          return this.$store.getters[`list/${this.listKey}/hasSelectFeature`]
      },
      usedListEmpty() {
          return this.$store.getters[`list/${this.listKey}/usedListEmpty`]
      },
      isPopupMode() {
          return this.$store.getters[`list/${this.listKey}/isPopupMode`]
      },
      selectorSingleMode() {
          return this.$store.getters[`list/${this.listKey}/selectorSingleMode`]
      },
      hasData() {
          return this.$store.getters[`list/${this.listKey}/hasData`]
      },
      targetKey() {
          return this.$store.getters[`list/${this.listKey}/targetKey`]
      },
      listActions() {
          return this.$store.getters[`list/${this.listKey}/actions`]
      },
      hasSelectedAll() {
          return this.$store.getters[`list/${this.listKey}/hasSelectedAll`]
      },
      listConfig() {
          return this.$store.getters[`list/${this.listKey}/config`]
      },
      listData() {
          return this.$store.getters[`list/${this.listKey}/data`]
      },
      listHasSelected() {
          return this.$store.getters[`list/${this.listKey}/hasSelected`]
      },
  },
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      return await this.$api.collection.productMenuPivotApi.index(this.providerId, this.target, params)
    },
    async deleteApi(target) {
      return await this.$api.collection.productMenuPivotApi.delete(this.providerId,target)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.productMenuPivotApi.batchDelete(this.providerId, targets)
    },
    getListConfig() {
      return listConfig
    },
    toggleSelectAll() {
          // 已全選, 取消全選
          if(this.hasSelectedAll) {
              this.$store.dispatch(`list/${this.listKey}/setSelected`, [])
              return
          }


          // 未全選, 執行全選
          const selected = this.listData.map(row => {
              if(typeof this.listConfig.selectedData === 'function') {
                  return this.listConfig.selectedData(row)
              }

              return { [this.targetKey]: row[this.targetKey] }
          })
          this.$store.dispatch(`list/${this.listKey}/setSelected`, selected)
      },

  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
